import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import "./App.css";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import { getColor } from "@braze/beacon-styling";
import { FlexBox } from "@braze/beacon-core";

import { Navbar } from "src/components/nav/Navbar";
import { Footer } from "./components/nav/Footer";
import { Navigation } from "src/navigation";
import { Feedback } from "./components/Feedback";

const oktaAuthClient = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: "https://braze.okta.com",
  redirectUri: window.location.origin + "/login/callback",
});

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    const relativeUrl = toRelativeUrl(originalUri || "/", window.location.origin);
    navigate(relativeUrl, {
      replace: true,
    });
  };

  return (
    <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
      <AppBox>
        {location.pathname !== "/" && <Navbar />}
        <Navigation />
        <Footer />
        <Feedback />
      </AppBox>
    </Security>
  );
}

const AppBox = styled(FlexBox)`
  flex-direction: column;
  min-width: 800px;
  min-height: 100vh;
  background: ${getColor("gray", 50)};
  color: ${getColor("gray", 800)};
`;

export default App;
