import { Box, Button, Checkbox, FlexBox } from "@braze/beacon-core";
import React, { useEffect, useState } from "react";
import { Select } from "src/components/shared/Select";
import styled from "styled-components";
import { EmployeeCountComparator, TableFilter } from "../types";
import { OptionTypeBase } from "react-select";

type TableFiltersProps = {
  isOpen: boolean;
  regions: string[];
  classifications: string[];
  industries: string[];
  employeeCounts: number[];

  onFilter(filters: TableFilter[]): void;
};

const composeOptions = (values: (string | number)[]) => {
  return values.map((val) => ({ label: val, value: val }));
};

const composeCountOptions = (values: number[]) => {
  const options: OptionTypeBase[] = [];
  if (values.some((v) => v <= 100)) {
    options.push({
      label: "<100",
      value: EmployeeCountComparator.LessThanHundred,
    });
  }
  if (values.some((v) => v > 100 && v <= 1000)) {
    options.push({
      label: "101-1000",
      value: EmployeeCountComparator.LessThanThousand,
    });
  }
  if (values.some((v) => v > 1000)) {
    options.push({
      label: "1000+",
      value: EmployeeCountComparator.MoreThanThousand,
    });
  }
  return options;
};

export const TableFilters = ({
  isOpen,
  regions,
  classifications,
  industries,
  employeeCounts,
  onFilter,
}: TableFiltersProps) => {
  const [selectedRegions, setSelectedRegions] = useState<OptionTypeBase[]>([]);
  const [selectedClassifications, setSelectedClassifications] = useState<
    OptionTypeBase[]
  >([]);
  const [selectedIndustries, setSelectedIndustries] = useState<
    OptionTypeBase[]
  >([]);
  const [selectedEmployeeCounts, setSelectedEmployeeCounts] = useState<
    OptionTypeBase[]
  >([]);
  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState(false);

  useEffect(() => {
    onFilter([
      { type: "region", values: selectedRegions.map((v) => v.value) },
      {
        type: "classification",
        values: selectedClassifications.map((v) => v.value),
      },
      {
        type: "industry_micro",
        values: selectedIndustries.map((v) => v.value),
      },
      {
        type: "employee_count",
        values: selectedEmployeeCounts.map((v) => v.value),
      },
      { type: "verbal_consent", values: consentCheckboxChecked },
    ]);
  }, [
    selectedRegions,
    selectedClassifications,
    selectedIndustries,
    selectedEmployeeCounts,
    consentCheckboxChecked,
    onFilter,
  ]);

  const onReset = () => {
    setSelectedRegions([]);
    setSelectedClassifications([]);
    setSelectedIndustries([]);
    setSelectedEmployeeCounts([]);
    setConsentCheckboxChecked(false);
  };

  return (
    <Box
      boxMargin={{ top: "xl" }}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <FlexBox justifyContent="space-between" alignItems="center">
        <Checkbox
          onChange={() => setConsentCheckboxChecked(!consentCheckboxChecked)}
          checked={consentCheckboxChecked}
        >
          Consent to share name verbally
        </Checkbox>
        <Button size="sm" variant="secondary" onClick={() => onReset()}>
          Clear All
        </Button>
      </FlexBox>
      <FlexBox
        data-testid="filter-box"
        justifyContent="space-between"
        boxMargin={{ top: "lg" }}
        wrap="wrap"
        style={{ rowGap: 12, columnGap: 24 }}
      >
        <FilterSelect
          isMulti
          isClearable
          isSearchable={false}
          closeMenuOnSelect={false}
          placeholder="Region"
          options={composeOptions(regions)}
          value={selectedRegions}
          onChange={(val: OptionTypeBase[]) => setSelectedRegions(val)}
        />
        <FilterSelect
          isMulti
          isClearable
          isSearchable={false}
          closeMenuOnSelect={false}
          placeholder="Classification"
          value={selectedClassifications}
          options={composeOptions(classifications)}
          onChange={(val: OptionTypeBase[]) => setSelectedClassifications(val)}
        />
        <FilterSelect
          isMulti
          isClearable
          isSearchable={false}
          closeMenuOnSelect={false}
          placeholder="Industry"
          value={selectedIndustries}
          options={composeOptions(industries)}
          onChange={(val: OptionTypeBase[]) => setSelectedIndustries(val)}
        />
        <FilterSelect
          isMulti
          isClearable
          isSearchable={false}
          closeMenuOnSelect={false}
          placeholder="Employee Count"
          value={selectedEmployeeCounts}
          options={composeCountOptions(employeeCounts)}
          onChange={(val: OptionTypeBase[]) => setSelectedEmployeeCounts(val)}
        />
      </FlexBox>
    </Box>
  );
};

const FilterSelect = styled(Select)`
  flex: 1;
  min-width: 200px;
  max-width: 400px;
`;
