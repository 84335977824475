import React from "react";

const sizes = {
  xs: "18px",
  sm: "20px",
  md: "22px",
  lg: "24px",
  xl: "30px",
  xxl: "40px",
};

const sizeToType = {
  xs: "h6",
  sm: "h5",
  md: "h4",
  lg: "h3",
  xl: "h2",
  xxl: "h1",
};

type DopperHeadingProps = {
  children: React.ReactNode;
  size?: keyof typeof sizes;
  fontWeight?: 300 | 400 | 500 | 600 | 700;
  style?: React.CSSProperties;
};

export const DopperHeading = ({
  children,
  size = "md",
  fontWeight = 500,
  style,
}: DopperHeadingProps) => {
  const Tag = `${sizeToType[size]}` as keyof JSX.IntrinsicElements;

  return (
    <Tag
      style={{
        margin: 0,
        padding: 0,
        fontSize: sizes[size],
        fontWeight: fontWeight,
        ...style,
      }}
    >
      {children}
    </Tag>
  );
};
