import React, { useState } from 'react';
import { Box, FlexBox } from "@braze/beacon-core"
import { ApptopiaDataTable } from 'src/components/apptopia/ApptopiaDataTable';
import { useApptopiaPublisher } from "src/hooks/useApptopiaPublisher";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useApptopiaSdk } from 'src/hooks/useApptopiaSdk';
import { DataTableValueArray } from 'primereact/datatable';
// import { DataTableStateEvent } from 'src/api/types';

const columnNames = ["apptopia_sdk.name", "apptopia_sdk.install_date", "id", "name", "total_number_of_apps", "total_mau", "total_downloads", "total_iap_revenue", "total_revenue_per_user", "total_revenue"]

const handleSdkFilterSetup = (event: any) => {
  /**
   * This is for us to alter the payload for SDKs to match the format of the backend. 
   * Currently uses PrimeReact front + backend.
   */
  const x: any = null

  const sdkFilters = event?.filters?.sdks?.value?.sdks

  const installStartDate = sdkFilters?.start_date
  const installEndDate = sdkFilters?.end_date
  const sdkNames = sdkFilters?.sdk_name

  const payloadSdkNames = {
    operator: FilterOperator.OR,
    constraints: [
      { value: x, matchMode: FilterMatchMode.EQUALS }
    ]
  }

  if (sdkNames) {
    payloadSdkNames.constraints.pop()
    sdkNames.forEach((sdk: string) => {
      payloadSdkNames.constraints.push({ value: sdk, matchMode: FilterMatchMode.EQUALS })
    })
  }

  const payloadInstallDate = {
    operator: FilterOperator.AND, constraints: [{
      value: x, matchMode: FilterMatchMode.DATE_IS
    }]
  }
  if (installStartDate || installEndDate) {
    payloadInstallDate.constraints.pop()
  }
  if (installStartDate) {
    payloadInstallDate.constraints.push({ value: installStartDate, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO })
  }
  if (installEndDate) {
    payloadInstallDate.constraints.push({ value: installEndDate, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO })
  }

  event.filters["apptopia_sdk.name"] = payloadSdkNames
  event.filters["apptopia_sdk.install_date"] = payloadInstallDate

  return event
}



const removeNullFilters = (filterObject: { filters: { [x: string]: any; }; }) => {
  /** 
   * Filters by default are included with null values. 
   * Remove to decrease size of parameters sent to API. 
   */
  columnNames.forEach(col => {
    if (filterObject.filters[col] && !filterObject.filters[col]?.constraints[0]?.value) {
      delete filterObject.filters[col]
    }
  })
}



export const Apptopia = () => {

  const [params, setParams] = useState({
    first: 0,
    rows: 20,
    page: 0,
    filters: {}
  });

  const handleSetParams = (newParams: any) => {
    let newObject = JSON.parse(JSON.stringify(newParams)) // dont alter original obj.
    newObject = handleSdkFilterSetup(newObject)
    delete newObject.filters.sdks // remove temp sdk val holder --> to elimiate noise that we're sending.
    removeNullFilters(newObject)
    setParams(newObject)
  }


  const { data, isLoading, isError } = useApptopiaPublisher(params || undefined);
  const { data: sdkNames, isError: isSdkNamesError } = useApptopiaSdk();

  if (isError || isSdkNamesError) {
    return (
      <FlexBox alignItems='center' justifyContent='center' style={{ flex: 1 }}>Error loading data</FlexBox>
    )
  }

  return (
    <Box boxPadding="xxl">
      <ApptopiaDataTable sdkNames={sdkNames ?? []}
        data={data?.data as unknown as DataTableValueArray}
        totalDataRecords={data?.total_results}
        handleSetParams={handleSetParams} isLoading={isLoading} />
    </Box>
  )
}