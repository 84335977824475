import React from "react";
import { FlexBox, BodyText } from "@braze/beacon-core";
import { HighspotObject } from "src/api/types";
import { getColor } from "@braze/beacon-styling";
import styled from "styled-components";
import { Pill } from "src/components/shared/Pill";
import { DopperHeading } from "src/components/shared/DopperHeading";
import { logCustomEvent } from "@braze/web-sdk";
import { ExternalLink } from "src/components/shared/ExternalLink";
import { BarLoader } from "react-spinners";
import { RelativeLink } from "src/components/shared/RelativeLink";

type CaseStudiesProps = {
  caseStudies: HighspotObject[];
  loading?: boolean;
  isError?: boolean;
  title?: string;
};

const onCaseStudyClick = (company_name: string | undefined) => {
  if (company_name) {
    logCustomEvent("opened_industry_case_study");
  } else {
    logCustomEvent("opened_case_study");
  }
};

export const CaseStudies = ({
  caseStudies,
  loading,
  isError,
  title,
}: CaseStudiesProps) => {
  if (isError) {
    return (
      <>
        {title && <DopperHeading
          size="xs"
          fontWeight={600}
          style={{ marginBottom: 12, marginTop: title === "Industry" ? 12 : 0 }}
        >
          {title}
        </DopperHeading>
        }
        <Description>
          There was an error fetching similar case studies for this company
        </Description>
      </>
    );
  }
  return (
    <FlexBox direction="column">
      {title && <DopperHeading
        size="xs"
        fontWeight={600}
        style={{ marginBottom: 12, marginTop: title === "Industry" ? 12 : 0 }}
      >
        {title}
      </DopperHeading>}
      {loading ? (
        <ItemLoader />
      ) : (
        <CaseStudiesList>
          {!caseStudies?.length ? (
            <span style={{ marginBottom: 12, marginTop: 6 }}>
              No case studies available
            </span>
          ) : (
            caseStudies.map((highspotCase) => (
              <CompanyCaseStudy
                key={highspotCase.item_id}
                highspotCase={highspotCase}
              />
            ))
          )}
        </CaseStudiesList>
      )}
    </FlexBox>
  );
};

const CompanyCaseStudy = ({
  highspotCase,
}: {
  highspotCase: HighspotObject;
}) => {
  return (
    <CaseStudiesRow>
      <FlexBox direction="row" style={{ gap: 12, marginBottom: 6 }}>
        {highspotCase.company_name && (
          <>
            <CompanyLink
              to={`/company/${highspotCase.company_id}`}
              onClick={() => logCustomEvent("clicked_company_in_similarity")}
            >
              {highspotCase.company_name}
            </CompanyLink>
            -
          </>
        )}
        <ExternalLink
          href={`https://braze.highspot.com/items/${highspotCase.item_id}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => onCaseStudyClick(highspotCase.company_name)}
        >
          {highspotCase.title}
        </ExternalLink>
        <HighspotTypePill>{highspotCase.file_type}</HighspotTypePill>
      </FlexBox>
      <Description>{highspotCase.description}</Description>
    </CaseStudiesRow>
  );
};

const ItemLoader = () => (
  <BarLoader
    color="#801ED7"
    width="40%"
    cssOverride={{ marginTop: 16, marginBottom: 48 }}
  />
);

const CompanyLink = styled(RelativeLink)`
  font-weight: 700;
`;

const Description = styled(BodyText)`
  color: ${getColor("gray", 600)};
  margin-top: 0;
`;

const HighspotTypePill = styled(Pill)`
  padding: 1.5px 11px;
  color: white;
  background: #91186E;
  text-transform: capitalize;
  font-size: 11px;
`;

const CaseStudiesList = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
`;
const CaseStudiesRow = styled(FlexBox)`
  flex-direction: column;
  margin: 6px 0;
`;
