import React from "react";
import { Box } from "@braze/beacon-core";

type Styling = {
  height: number;
  baseHue: number;
  maxWidthPct: number;
  maxWidth?: number;
};

type StylingProps = Partial<Styling>;

type SimilarityBarProps = {
  similarity: number;
  styling?: StylingProps;
};

const hslPoints = {
  saturation: 96,
  light: 20,
};
const baseStyle: Styling = {
  height: 12,
  baseHue: 268,
  maxWidthPct: 95,
};

export const SimilarityIndicator = ({
  similarity,
  styling = {},
}: SimilarityBarProps) => {
  // Scaling creates a more dynamic display of similarity when the values
  // are close, for example for values between 0.95 and 0.9
  const similarityScaled = similarity * 1.5;

  const mergedStyle = { ...baseStyle, ...styling };
  const barWidthPct = (1 - similarityScaled) * mergedStyle.maxWidthPct;
  const saturationOffset = Math.floor(similarityScaled * 100);
  const lightOffset = Math.floor(similarityScaled * 150);
  const sat = hslPoints.saturation - saturationOffset;
  const light = hslPoints.light + lightOffset;
  const hsl = `hsl(${mergedStyle.baseHue}, ${sat}%, ${light}%)`;

  return (
    <Box
      style={{
        maxWidth: mergedStyle.maxWidth,
        width: `${barWidthPct}%`,
        height: mergedStyle.height,
        borderRadius: 20,
        margin: 0,
        padding: 0,
        background: hsl,
      }}
    />
  );
};
