import { getColor } from "@braze/beacon-styling";
import styled from "styled-components";

export const WebsiteText = styled.div`
  margin: 5px 0;
`;

export const EllipsisText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const InlineButton = styled.button`
  color: #300266;
  font-weight: 600;
  cursor: pointer;
`;
