import { Box } from "@braze/beacon-core";
import React from "react";
import styled from "styled-components";

export const Pill = styled(Box)`
  justify-content: center;
  align-content: center;
  padding: 2.5px 14px;
  border-radius: 20px;
  max-width: fit-content;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;
