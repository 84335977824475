import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from 'react';
import { FeedbackSubmissionModal } from './FeedbackSubmissionModal';

export const Feedback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<string | undefined>();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await oktaAuth.getUser();
        setUser(user.email);
      } catch (err) {
        console.log("Couldn't load user info", err);
      }
    };

    if (authState?.isAuthenticated) {
      fetchUser();
    }
  }, [authState, authState?.isAuthenticated]);


  if (user) {
    return (
      <>
        <FeedbackSubmissionModal productName='Doppelganger' email={user} />
      </>
    )
  }

  return null;
}
