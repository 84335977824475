import { Heading } from "@braze/beacon-core";
import React from "react";
import { CenterBox } from "src/components/shared/CenterBox";

export const NotFound = () => {
  return (
    <CenterBox style={{ flex: 1 }}>
      <Heading level={1}>Four oh Four</Heading>
    </CenterBox>
  );
};
