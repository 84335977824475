import { FlexBox } from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";
import React from "react";
import { Stronger } from "src/components/shared/Text";
import styled from "styled-components";

export const Footer = () => {
  return (
    <FooterBox
      boxMargin={{ horizontal: "xxl" }}
      boxPadding={{ vertical: "md" }}
    >
      <FooterText>Built by Growth Engineering.</FooterText>
      <FooterText style={{ marginLeft: 8 }}>
        Join us in <Stronger>#doppelganger</Stronger> for questions and
        feedback.
      </FooterText>
    </FooterBox>
  );
};

const FooterBox = styled(FlexBox)`
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
`;

const FooterText = styled.span`
  font-size: 0.9rem;
  color: ${getColor("gray", 600)};
`;
