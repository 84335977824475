import React from "react";
import { ClipLoader } from "react-spinners";
import { CenterBox } from "../CenterBox";

type LoadingProps = {
  size?: number;
  style?: React.CSSProperties;
};

export const Loading = ({ size = 66, ...props }: LoadingProps) => {
  return (
    <CenterBox style={{ flex: 1 }} {...props}>
      <ClipLoader color="#801ED7" size={size} />
    </CenterBox>
  );
};
