import React, { useEffect, useState } from "react";
import DoppelgangerLogo from "../../../assets/doppelganger-logo.svg";

import { Button, FlexBox, Icon } from "@braze/beacon-core";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import { changeUser, openSession } from "@braze/web-sdk";

export const Navbar = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<string | undefined>();

  const signOut = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      console.log("Error logging out");
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await oktaAuth.getUser();
        setUser(user.email);
      } catch (err) {
        console.log("Couldn't load user info", err);
      }
    };

    if (authState?.isAuthenticated) {
      fetchUser();
    }
  }, [authState, authState?.isAuthenticated]);

  useEffect(() => {
    if (user) {
      startBrazeSession(user);
    }
  }, [user]);

  return (
    <FlexBox
      boxPadding={{ horizontal: "xxl" }}
      alignItems="center"
      justifyContent="space-between"
      style={{
        minHeight: 80,
        background: "white",
        boxShadow: "0px 4px 8px rgba(99, 99, 99, 0.1)",
      }}
    >
      <Link to="/">
        <img src={DoppelgangerLogo} style={{ height: 44 }} />
      </Link>
      {authState?.isAuthenticated && (
        <FlexBox direction="column" alignItems="flex-end" style={{ gap: 8 }}>
          <span style={{ visibility: user ? "visible" : "hidden" }}>
            Signed in as <strong>{user}</strong>
          </span>
          {/* <Button size="sm" variant="tertiary" onClick={signOut}>
            <FlexBox style={{ gap: 8, alignItems: "center" }}>
              <span>Logout</span>
              <Icon name="sign-out-alt" />
            </FlexBox>
          </Button> */}
        </FlexBox>
      )}
    </FlexBox>
  );
};

const startBrazeSession = (user: string) => {
  changeUser(user.toLowerCase());
  openSession();
};
