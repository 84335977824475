import { CompanyLimited } from "src/api/types";

export enum EmployeeCountComparator {
  LessThanHundred = 0,
  LessThanThousand = 1,
  MoreThanThousand = 2,
}

export type TableFilter = {
  type: keyof CompanyLimited;
  values: boolean | (string | number)[];
};

export type CompanyRow = {
  similarity_index?: number;
  company: CompanyLimited;
};
