import React, { useState } from "react";
import AsyncSelect from "react-select/async";

import { getCompanyNames } from "src/api";
import { Box } from "@braze/beacon-core";
import { components, ValueContainerProps } from "react-select";
import { MdOutlineSearch } from "react-icons/md";
import { CompanyBase } from "src/api/types";
import { logCustomEvent } from "@braze/web-sdk";

type CompanyOption = {
  label: string;
  value: string;
};

const TYPE_TIME_OUT = 300;

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<CompanyOption, false>) => (
  <>
    <MdOutlineSearch size={22} style={{ marginLeft: 10, color: "#300266" }} />
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </>
);

const DropdownIndicator = undefined;

type CompanySelectProps = {
  company?: CompanyBase;
  onCompanySelected: (company: CompanyBase) => void;
  onError: (errorMessage: string) => void;
};

let timer = setTimeout(() => undefined, TYPE_TIME_OUT);

export const CompanySelect = ({
  onCompanySelected,
  onError,
}: CompanySelectProps) => {
  const [isError, setIsError] = useState(false);

  const onSelectOption = (chosenOption: CompanyOption | null) => {
    if (!chosenOption) {
      return;
    }
    onCompanySelected({ id: chosenOption.value, name: chosenOption.label });
    logCustomEvent("searched");
  };

  const getOptions = async (input: string): Promise<CompanyOption[]> => {
    try {
      const companies = await getCompanyNames(input);
      return companies.map((c) => ({ label: c.name, value: c.id }));
    } catch (err) {
      onError(
        "Looks like we encountered an error.\nPlease try to refresh the page."
      );
      setIsError(true);
      return [];
    }
  };

  const promiseOptions = (inputValue: string) => {
    clearTimeout(timer);
    return new Promise<CompanyOption[]>((resolve) => {
      timer = setTimeout(async () => {
        resolve(getOptions(inputValue));
      }, TYPE_TIME_OUT);
    });
  };

  return (
    <Box style={{ width: "33%", minWidth: 350, marginBottom: 12 }}>
      <AsyncSelect
        inputId="company-select"
        placeholder="Search by company name"
        cacheOptions
        isClearable
        isDisabled={isError}
        noOptionsMessage={() => "No companies found"}
        onChange={(option) => onSelectOption(option)}
        loadOptions={promiseOptions}
        styles={{
          control: (provided) => ({
            ...provided,
            height: 45,
            fontSize: "1.05rem",
            borderRadius: 10,
          }),
          option: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#300266",
            primary25: "#e6eced",
          },
        })}
        components={{
          ValueContainer,
          DropdownIndicator,
        }}
      />
    </Box>
  );
};
