import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, FlexBox } from "@braze/beacon-core";

import { CompanyLimited, CompanyBase } from "src/api/types";
import { ElevatedCard } from "src/components/shared/ElevatedCard";
import { MdOutlineFilterList } from "react-icons/md";
import styled, { css } from "styled-components";
import { CompanyRow, EmployeeCountComparator, TableFilter } from "../types";
import { TableFilters } from "./TableFilters";
import { CompanyDataTable } from "./CompanyDataTable";
import { getColor } from "@braze/beacon-styling";
import { Link } from "react-router-dom";
import { useSimilarity } from "src/hooks/useSimilarity";
import { logCustomEvent } from "@braze/web-sdk";
import { SubHeading } from "src/components/shared/SubHeading";

type SimilarityResultsProps = {
  company: CompanyBase;
  page?: number;
  onChangePage?: (page: number) => void;
};

export const SimilarityResults = ({
  company,
  page,
  onChangePage,
}: SimilarityResultsProps) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [results, setResults] = useState<CompanyRow[]>([]);
  const { data: original, isLoading } = useSimilarity(company.id);

  useEffect(() => {
    setResults(original?.similar_companies || []);
  }, [original]);

  const extractUniqueValuesForKey = useCallback(
    (key: keyof CompanyLimited) =>
      Array.from(
        new Set(original?.similar_companies.map((c) => c.company[key]))
      ),
    [original]
  );

  const onFilterButtonClick = () => {
    if (!filtersOpen) {
      logCustomEvent("filtered");
    }
    setFiltersOpen(!filtersOpen);
  };

  const onFilter = useCallback(
    (filters: TableFilter[]) => {
      let filtered = original?.similar_companies || [];
      for (const { type, values } of filters) {
        if (!values || (Array.isArray(values) && values.length == 0)) {
          continue;
        }

        if (type === "verbal_consent") {
          filtered = filtered.filter(
            (c) => c.company.verbal_consent?.toLowerCase() === "yes"
          );
        } else if (type === "employee_count") {
          const bounds = (values as number[]).map((v) =>
            getEmployeeCountBounds(v)
          );
          const min = Math.min(...bounds.map((v) => v.min));
          const max = Math.max(...bounds.map((v) => v.max));
          filtered = filtered.filter(
            (c) =>
              c.company.employee_count >= min && c.company.employee_count <= max
          );
        } else {
          filtered = filtered.filter(
            (c) =>
              Array.isArray(values) &&
              c.company[type] != null &&
              values.includes(c.company[type] as string)
          );
        }
      }
      setResults(filtered);
    },
    [original]
  );

  return (
    <ElevatedCard boxMargin={{ top: "xl" }}>
      <Box boxPadding={{ horizontal: "xl", top: "lg", bottom: "xl" }}>
        <FlexBox justifyContent="space-between" alignItems="center">
          <SubHeading>
            Companies most similar to{" "}
            <Link to={`/company/${company.id}`}>
              <ClickableTableTitle>{company.name}</ClickableTableTitle>
            </Link>
          </SubHeading>
          <Button variant="tertiary" onClick={onFilterButtonClick}>
            <MdOutlineFilterList size={20} style={{ marginRight: 6 }} />
            <span>Filter</span>
          </Button>
        </FlexBox>
        <TableFilters
          isOpen={filtersOpen}
          regions={extractUniqueValuesForKey("region")}
          classifications={extractUniqueValuesForKey("classification")}
          industries={extractUniqueValuesForKey("industry_micro")}
          employeeCounts={extractUniqueValuesForKey("employee_count")}
          onFilter={onFilter}
        />
      </Box>
      <CompanyDataTable
        data={results}
        isLoading={isLoading}
        page={page}
        onChangePage={onChangePage}
        searchType="similarity"
      />
    </ElevatedCard>
  );
};

const baseTableTitleStyle = css`
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  font-weight: 400;
`;

const ClickableTableTitle = styled.button`
  ${baseTableTitleStyle}
  color: ${getColor("lightBlue", 500)};
  cursor: pointer;
`;

const getEmployeeCountBounds = (values: number) => {
  if (values === EmployeeCountComparator.LessThanHundred) {
    return {
      min: 0,
      max: 100,
    };
  } else if (values === EmployeeCountComparator.LessThanThousand) {
    return {
      min: 101,
      max: 1000,
    };
  }
  return {
    min: 1001,
    max: Infinity,
  };
};
