import React, { useState } from "react";
import styled from "styled-components";
import { Company } from "src/api/types";
import { PaddedElevatedCard } from "../PaddedElevatedBox";
import { CardTitle } from "../CardTitle";
import { Box } from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";
import { ExternalLink } from "src/components/shared/ExternalLink";
import { logCustomEvent } from "@braze/web-sdk";
import { EllipsisText, InlineButton, WebsiteText } from "./styles";

type CompanyProps = {
  company: Company;
};

export const CompanyInformation = ({ company }: CompanyProps) => {
  const [showDesc, setShowDesc] = useState(false);

  const shouldHideExpandButton =
    showDesc ||
    !company.description ||
    company.description.split(" ").length < 30;

  return (
    <PaddedElevatedCard>
      <CardTitle>{company.name}</CardTitle>
      <WebsiteText>
        Website:{" "}
        {company.website ? (
          <ExternalLink
            data-testid="company-link"
            href={`http://${company.website}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => logCustomEvent("clicked_company_website")}
          >
            {company.website}
          </ExternalLink>
        ) : (
          "NA"
        )}
      </WebsiteText>
      <WebsiteText>
        Salesforce:{" "}
        <ExternalLink
          data-testid="salesforce-link"
          href={`https://braze.lightning.force.com/${company.id}`}
          target="_blank"
          onClick={() => logCustomEvent("clicked_salesforce_link")}
        >
          {company.id}
        </ExternalLink>
      </WebsiteText>
      <Box style={{ marginTop: 18 }}>
        {shouldHideExpandButton ? (
          <span>{company.description}</span>
        ) : (
          <>
            <EllipsisText>{company.description}</EllipsisText>
            <InlineButton onClick={() => setShowDesc(true)}>
              Show all
            </InlineButton>
          </>
        )}
      </Box>
    </PaddedElevatedCard>
  );
};

