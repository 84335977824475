import React, { useCallback, useState } from "react";
import { FieldLabel, FlexBox } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";
import { OptionProps } from "react-select/src/types";
import { RequiredLabel } from "src/components/shared/RequiredLabel";
import { Filters } from "./types";
import { useGetCriteriaFilters } from "src/hooks/useGetCriteriaFilters";
import { FilterValues } from "src/hooks/useGetCriteriaFilters/useGetCriteriaFilters";
import { ErrorBanner } from "src/components/similarity/Banner";


type FiltersPanelProps = {
  onFormStateChange: (formState: Filters) => void;
}

const mapFilterValuesToOptions = (values?: string[]) => values?.map(value => ({ label: value, value }));


export const FiltersPanel = ({ onFormStateChange }: FiltersPanelProps) => {
  const [formState, setFormState] = useState({
    hasHighspots: null,
    industry: null,
    region: null,
    classification: null,
    consent: [],
    channels: [],
    partners: [],
  });

  const { data, isLoading, error } = useGetCriteriaFilters();

  const handleSelectChange = useCallback((name: string) => (option: OptionProps) => {
    setFormState(prevState => {
      const newState = {
        ...prevState,
        [name]: option ? option.value : null,
      };
      onFormStateChange(newState);
      return newState;
    });
  }, [onFormStateChange]);

  const handleMultiSelectChange = useCallback((name: string) => (options: [OptionProps]) => {
    setFormState(prevState => {
      const newState = {
        ...prevState,
        [name]: options ? options.map(option => option.value) : [],
      };
      onFormStateChange(newState);
      return newState;
    });
  }, [onFormStateChange]);

  if (error) {
    return <ErrorBanner errorMessage={"Error loading filter data. Please try again"} />;
  }

  return (
    <FlexBox style={{ gap: "3%", margin: 20 }} wrap="wrap">
      <FieldLabel
        label="Industry"
        htmlFor="industry"
        formatLabel={RequiredLabel}
      >
        <Select
          inputId="industry"
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.industry)}
          placeholder="Select a industry"
          isClearable
          isSearchable
          isLoading={isLoading}
          onChange={handleSelectChange("industry")}
        />
      </FieldLabel>

      <FieldLabel
        label="Region"
        htmlFor="region"
        formatLabel={RequiredLabel}
      >
        <Select
          inputId="region"
          placeholder="Select a region"
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.region)}
          onChange={handleSelectChange("region")}
          isLoading={isLoading}
        />
      </FieldLabel>

      <FieldLabel
        label="Classification"
        htmlFor="classification"
      >
        <Select
          inputId="classification"
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.classification)}
          placeholder="Select a classification"
          isClearable
          isSearchable
          onChange={handleSelectChange("classification")}
          isLoading={isLoading}
        />
      </FieldLabel>

      <FieldLabel
        label="Consent"
        htmlFor="consent"
      >
        <Select
          inputId="consent"
          isMulti
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.consent)}
          placeholder="Select a partner"
          isClearable
          isSearchable
          onChange={handleMultiSelectChange("consent")}
          isLoading={isLoading}
        />
      </FieldLabel>

      <FieldLabel
        label="Channels"
        htmlFor="channels"
      >
        <Select
          inputId="channels"
          isMulti
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.channels)}
          placeholder="Select a channel"
          isClearable
          isSearchable
          onChange={handleMultiSelectChange("channels")}
          isLoading={isLoading}
        />
      </FieldLabel>

      <FieldLabel
        label="Partners"
        htmlFor="partners"
      >
        <Select
          inputId="partners"
          isMulti
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.partners)}
          placeholder="Select a partner"
          isClearable
          isSearchable
          onChange={handleMultiSelectChange("partners")}
          isLoading={isLoading}
        />
      </FieldLabel>

      <FieldLabel
        label="Has Highspots"
        htmlFor="highspots"
      >
        <Select
          inputId="highspots"
          options={isLoading ? [] : mapFilterValuesToOptions((data as FilterValues)?.hasHighspots)}
          placeholder="Select if has highspots"
          isClearable
          isSearchable
          onChange={handleSelectChange("hasHighspots")}
          isLoading={isLoading}
        />
      </FieldLabel>

    </FlexBox>
  );
};