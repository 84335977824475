import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SimilaritySideBar } from "src/components/dashboard/SimilaritySideBar";
import { CompanyDetails } from "src/components/dashboard/CompanyDetails";
import { FlexBox } from "@braze/beacon-core";
import { CompanyInformation } from "src/components/dashboard/CompanyInformation";
import { PartnerInformation } from "src/components/dashboard/PartnerInformation";
import styled from "styled-components";
import { ErrorBanner } from "src/components/similarity/Banner";
import { Loading } from "src/components/shared/Loading";
import { useCompany } from "src/hooks/useCompany";
import { useSimilarity } from "src/hooks/useSimilarity";
import { logCustomEvent } from "@braze/web-sdk";
import { useSimilarHighspot } from "src/hooks/useSimilarHighspot";

export const CompanyDashboard = () => {
  const { id } = useParams();

  useEffect(() => {
    logCustomEvent("visited_company_dashboard");
  }, []);

  const { data, isLoading, isError } = useCompany(id || "");
  const {
    data: similarCompanies,
    isLoading: isLoadingSimilarCompanies,
    isError: isErrorSimilarCompanies,
  } = useSimilarity(id || "");

  if (isLoading) {
    return <Loading />;
  }

  if (isError || data === undefined || !id) {
    return <ErrorBanner errorMessage="No company data available." />;
  }

  return (
    <CompanyContainer>
      <LeftColumn>
        <CompanyInformation company={data} />
        <PartnerInformation partners={data.partners} />
        <SimilaritySideBar
          companies={similarCompanies?.similar_companies || []}
          isLoading={isLoadingSimilarCompanies}
          isError={isErrorSimilarCompanies}
        />
      </LeftColumn>
      <RightColumn>
        <CompanyDetails company={data} />
      </RightColumn>
    </CompanyContainer>
  );
};

const CompanyContainer = styled(FlexBox)`
  flex: 1;
  padding: 2em 4em;
  gap: 2em;
`;

const LeftColumn = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
  gap: 2em;
`;

const RightColumn = styled(FlexBox)`
  flex: 3;
  flex-direction: column;
  gap: 2em;
`;
