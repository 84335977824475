import { FlexBox } from "@braze/beacon-core";
import React from "react";
import { DopperHeading } from "src/components/shared/DopperHeading";
import styled from "styled-components";
import { ProductStat } from "./ProductStat";

type ProductUsageData = {
  iamImpressions: number;
  mobilePushes: number;
  webPushes: number;
  emails: number;
  contentCards: number;
  sms: number;
  roku: number;
};

type ProductUsageProps = {
  productUsage: ProductUsageData;
};

export const ProductUsage = ({ productUsage }: ProductUsageProps) => {
  return (
    <ProductUsageBox>
      <DopperHeading
        size="xs"
        fontWeight={600}
        style={{ marginTop: 12, marginBottom: 12, color: "#801ED7" }}
      >
        Product Usage
      </DopperHeading>
      <FlexBox
        justifyContent="space-between"
        boxMargin={{ right: "md" }}
        style={{ gap: "3em" }}
      >
        <ProductStat
          title="IAM Impressions"
          value={productUsage.iamImpressions}
        />
        <ProductStat title="Emails Sent" value={productUsage.emails} />
        <ProductStat title="SMS Sent" value={productUsage.sms} />
        <ProductStat
          title="Content Card Impressions"
          value={productUsage.contentCards}
        />
        <ProductStat
          title="Mobile Push Sent"
          value={productUsage.mobilePushes}
        />
        <ProductStat title="Web Push Sent" value={productUsage.webPushes} />
        <ProductStat title="Roku" value={productUsage.roku} />
      </FlexBox>
    </ProductUsageBox>
  );
};

const ProductUsageBox = styled(FlexBox)`
  flex-direction: column;
`;
