import React from "react";
import { DopperHeading } from "src/components/shared/DopperHeading/DopperHeading";

export const CardTitle = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <DopperHeading size="md" fontWeight={600} style={{ marginBottom: 12 }} {...props}>
    {children}
  </DopperHeading>
);
