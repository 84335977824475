import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import BeaconThemeProvider from "@braze/beacon-styling";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import { initializeBraze } from "src/util/config";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

initializeBraze();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      /* 30 minute cache time - the results from the API will be removed 30
         minutes after the component unmounts */
      cacheTime: 1000 * 60 * 30,
      /* 12 hour stale time - the data will be marked as stale and will be
         re-fetched (even if it is still in cache) */
      staleTime: 1000 * 60 * 60 * 12,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BeaconThemeProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </BeaconThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
