import { CompanyBase } from "./types.d";
import { DoppelgangerApi } from "./axios";

type ApiResponse<T> = {
  message: string;
  result: T;
};

export const get = async <T>(
  endpoint: string,
  params?: Record<string, string | number | boolean>
) => {
  const { data } = await DoppelgangerApi.get<ApiResponse<T>>(endpoint, {
    params: params,
  });
  return data.result;
};

export const getCompanyNames = async (prefix: string) =>
  get<CompanyBase[]>(`companies/search`, {
    name: prefix,
    limit: 25,
  });

export const post = async <T>(endpoint: string, body?: Record<string, any>) => {
  const { data } = await DoppelgangerApi.post<ApiResponse<T>>(endpoint, body);
  return data.result;
};
