import { FlexBox } from "@braze/beacon-core";
import React from "react";
import styled from "styled-components";
import { DopperHeading } from "../DopperHeading";
import { ExternalLink } from "../ExternalLink";

const defaultStyle = {
  flex: 1,
};

type ListItem = {
  label: string;
  value?: string | null;
  url?: string;
};

type FlatListProps = {
  title?: string;
  data: ListItem[];
  style?: React.CSSProperties;
};

export const FlatList = ({
  title,
  data,
  style = defaultStyle,
}: FlatListProps) => {

  return (
    <FlatListBox flex={style.flex}>
      {title && <DopperHeading
        size="xs"
        fontWeight={600}
        style={{ marginBottom: 12, color: "#801ED7" }}
      >
        {title}
      </DopperHeading>}
      <FlexBox direction="column" style={{ gap: 8 }}>
        {data.map((current) => {
          const value = current.value ? current.value : "NA";
          const valueEl = current.url ? (
            <ExternalLink href={current.url} target="_blank">
              {value}
            </ExternalLink>
          ) : (
            value
          );

          return (
            <FlexBox key={`${current.label}_${value}`}>
              <RowLabel>{current.label}:</RowLabel>
              <RowValue>{valueEl}</RowValue>
            </FlexBox>
          );
        })}
      </FlexBox>
    </FlatListBox>
  );
};

const RowLabel = styled.span`
  font-weight: 400;
`;

const RowValue = styled.span`
  font-weight: 700;
  margin-left: 6px;
`;

type FlatListBoxProps = Pick<React.CSSProperties, "flex">;

const FlatListBox = styled(FlexBox) <FlatListBoxProps>`
  flex: ${(props) => props.flex};
  flex-direction: column;
`;
