import * as braze from "@braze/web-sdk";

/**
 * If the environment is defined as development, some functionality is disabled.
 * Some of the disabled features include:
 *  - changing a Braze user
 *  - opening a new session
 */
export const isProduction = process.env.NODE_ENV === "production";

/**
 * Initializes the Braze SDK. It starts out in the disabled in order to not create
 * new sessions for anon users when Cypress testing.
 */
export const initializeBraze = () => {
  const brazeAPIKey = process.env.REACT_APP_BRAZE_API_KEY;
  const brazeAPIUrl = process.env.REACT_APP_BRAZE_API_URL;
  braze.disableSDK();

  if (!isProduction) {
    console.log("Non-production environment - disabling Braze SDK");
    return;
  }

  if (!brazeAPIKey || !brazeAPIUrl) {
    console.log("Braze SDK not initialized - missing credentials");
    return;
  }

  braze.enableSDK();
  braze.initialize(brazeAPIKey, {
    baseUrl: brazeAPIUrl,
  });
  braze.automaticallyShowInAppMessages();
};
