import React from "react";

import { Company } from "src/api/types";
import { FlexBox } from "@braze/beacon-core";
import { FlatList } from "src/components/shared/FlatList";
import { ProductUsage } from "src/components/dashboard/ProductUsage";
import { PaddedElevatedCard } from "../PaddedElevatedBox";
import { CardTitle } from "../CardTitle";
import { HighSpots } from "../HighSpots";

type CompanyProps = {
  company: Company;
};

export const CompanyDetails = ({ company }: CompanyProps) => {
  const account = [
    {
      label: "Industry",
      value: company.industry_micro,
    },
    {
      label: "Employee Count",
      value: company.employee_count?.toLocaleString() ?? "NA",
    },
    {
      label: "Region",
      value: company.region,
    },
    {
      label: "Classification",
      value: company.classification,
    },
  ];

  const contact = [
    {
      label: "Account Owner",
      value: company.account_owner ? company.account_owner.name : "NA",
      url: company.account_owner
        ? `https://braze.lightning.force.com/${company.account_owner.id}`
        : undefined,
    },
    {
      label: "Onboarding Manager",
      value: company.onboarding_manager
        ? company.onboarding_manager.name
        : "NA",
      url: company.onboarding_manager
        ? `https://braze.lightning.force.com/${company.onboarding_manager.id}`
        : undefined,
    },
    {
      label: "Success Manager",
      value: company.success_manager ? company.success_manager.name : "NA",
      url: company.success_manager
        ? `https://braze.lightning.force.com/${company.success_manager.id}`
        : undefined,
    },
  ];

  const consent = [
    {
      label: "Verbal",
      value: company.verbal_consent,
    },
    {
      label: "Written",
      value: company.written_consent,
    },
    {
      label: "Logo",
      value: company.logo_consent,
    },
  ];

  const productUsageData = {
    iamImpressions: company.iam_impressions,
    mobilePushes: company.mobile_pushes,
    webPushes: company.web_pushes,
    emails: company.emails,
    contentCards: company.content_cards,
    sms: company.sms,
    roku: company.roku,
  };

  return (
    <PaddedElevatedCard style={{ paddingBottom: "4em", gap: "2em" }}>
      <CardTitle style={{ marginBottom: 0 }}>Company Details</CardTitle>
      <FlexBox>
        <FlatList title="Account" data={account} />
        <FlatList title="Contact" data={contact} />
        <FlatList title="Consent" data={consent} style={{ flex: 0.5 }} />
      </FlexBox>
      <ProductUsage productUsage={productUsageData} />
      <HighSpots caseStudies={company.highspots} companyId={company.id} />
    </PaddedElevatedCard>
  );
};
