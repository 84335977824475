import { FlexBox } from "@braze/beacon-core";
import React from "react";
import styled from "styled-components";

/**
 * A display card with slight elevation, perfect for creating separation
 *  when displaying data
 */
export const ElevatedCard = styled(FlexBox)`
  flex-direction: column;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 2px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px;
`;
