import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FlexBox } from "@braze/beacon-core";

import { SimilarityResults } from "../../components/similarity/SimilarityResults";
import { CompanySelect } from "src/components/similarity/CompanySelect";
import { ErrorBanner, WelcomeBanner } from "src/components/similarity/Banner";
import { CompanyBase } from "src/api/types";
import { logCustomEvent } from "@braze/web-sdk";

export const Similarity = () => {
  const [company, setCompany] = useState<CompanyBase | undefined>();
  const [searchError, setSearchError] = useState<string | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    logCustomEvent("opened_v2_default_search_page");
  }, []);

  useEffect(() => {
    const searchId = searchParams.get("id");
    const searchName = searchParams.get("name");

    if (searchId && searchName) {
      setCompany({ id: searchId, name: searchName });
    } else {
      setCompany(undefined);
    }
  }, [searchParams]);

  const onCompanySelected = (company: CompanyBase) => {
    setSearchParams({ id: company.id, name: company.name, page: "1" });
  };

  const onChangePage = (page: number) => {
    if (company) {
      setSearchParams({
        id: company.id,
        name: company.name,
        page: page.toString(),
      });
    }
  };

  return (
    <FlexBox
      boxPadding={{ horizontal: "xxxl", vertical: "xxl" }}
      style={{ flex: 1 }}
      direction="column"
    >
      <CompanySelect
        onCompanySelected={onCompanySelected}
        company={company}
        onError={(e) => setSearchError(e)}
      />
      {searchError && <ErrorBanner errorMessage={searchError} />}
      {!company && !searchError && <WelcomeBanner />}
      {company && !searchError && (
        <SimilarityResults
          company={company}
          page={Number(searchParams.get("page"))}
          onChangePage={onChangePage}
        />
      )}
    </FlexBox>
  );
};
