import { FlexBox, FlexBoxProps } from "@braze/beacon-core";
import React from "react";
import styled from "styled-components";

/**
 * Creates a FlexBox with all its content centered
 */
export const CenterBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
`;
