import React, { useState } from "react";

import BackgroundGradient from "../../assets/braze-bg-gradient.svg";

import { Navigate } from "react-router-dom";
import { CenterBox } from "src/components/shared/CenterBox";
import { useOktaAuth } from "@okta/okta-react";
import { Loading } from "src/components/shared/Loading";
import { useColor } from "@braze/beacon-styling";
import { ElevatedCard } from "src/components/shared/ElevatedCard";
import { Box, Button, FlexBox } from "@braze/beacon-core";
import { DopperHeading } from "src/components/shared/DopperHeading";

export const Login = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [error, setError] = useState(false);

  const color = useColor();
  const loginButtonColor = color("lightBlue", 300);

  const onLogIn = async () => {
    try {
      await oktaAuth.signInWithRedirect();
    } catch (e) {
      setError(true);
    }
  };

  if (!authState) {
    return <Loading />;
  }

  if (authState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <CenterBox
      direction="column"
      style={{
        flex: 1,
        backgroundImage: `url(${BackgroundGradient})`,
        backgroundSize: "cover",
      }}
    >
      <ElevatedCard
        style={{
          padding: "4em 4.25em",
          gap: "3em",
        }}
      >
        <FlexBox direction="column" style={{ gap: "1.5em" }}>
          <DopperHeading size="xxl">dop·pel·gäng·er</DopperHeading>
          <DopperHeading fontWeight={300} style={{ letterSpacing: 1.25 }}>
            /ˈdäpəlˌgaNGər/
          </DopperHeading>
          <DopperHeading
            size="xs"
            fontWeight={300}
            style={{ fontStyle: "italic", color: color("gray", 500) }}
          >
            noun
          </DopperHeading>
          <Box>
            <DopperHeading
              size="sm"
              fontWeight={400}
              style={{ marginLeft: 14 }}
            >
              1. a customer account that closely resembles your prospect
            </DopperHeading>
            <DopperHeading
              size="xs"
              fontWeight={400}
              style={{
                marginLeft: 35,
                marginTop: 4,
                color: color("gray", 400),
              }}
            >
              &quot;Megadeth is the doppelganger of my prospect Metallica&quot;
            </DopperHeading>
          </Box>
        </FlexBox>
        <FlexBox direction="column">
          <Button
            style={{
              background: loginButtonColor,
              borderColor: loginButtonColor,
            }}
            onClick={onLogIn}
          >
            Sign in with Okta
          </Button>
          {error && (
            <span style={{ color: color("red", 400), marginTop: 4 }}>
              Error occurred while connecting to Okta.
            </span>
          )}
        </FlexBox>
      </ElevatedCard>
    </CenterBox>
  );
};
