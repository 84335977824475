import { useIntentColor } from "@braze/beacon-styling";
import React from "react";
import ReactSelect, {
  GroupTypeBase,
  OptionTypeBase,
  Props as ReactSelectProps,
} from "react-select";

export const Select = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  ...props
}: ReactSelectProps<OptionType, IsMulti, GroupType>) => {
  const getIntentColor = useIntentColor();
  return (
    <ReactSelect
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: getIntentColor("primary", 500) as string,
          primary75: getIntentColor("primary", 400) as string,
          primary50: getIntentColor("primary", 300) as string,
          primary25: getIntentColor("primary", 100) as string,
        },
      })}
    />
  );
};
