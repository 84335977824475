import React from "react";

import { PaddedElevatedCard } from "../PaddedElevatedBox";
import { CardTitle } from "../CardTitle";
import { Loading } from "src/components/shared/Loading";
import { Box, FlexBox } from "@braze/beacon-core";
import { SimilarityIndicator } from "src/components/similarity/SimilarityIndicator";
import { CenterBox } from "src/components/shared/CenterBox";
import { SimilarCompany } from "src/api/types";
import { RelativeLink } from "src/components/shared/RelativeLink";
import { logCustomEvent } from "@braze/web-sdk";

const MAX_SIZE_LIST = 5;

type SimilaritySideBarProps = {
  companies: SimilarCompany[];
  isLoading: boolean;
  isError: boolean;
};

export const SimilaritySideBar = ({
  companies,
  isLoading,
  isError,
}: SimilaritySideBarProps) => {
  if (isLoading) {
    return (
      <PaddedElevatedCard>
        <CardTitle>Similar Companies</CardTitle>
        <CenterBox boxMargin={{ top: "sm" }}>
          <Loading size={44} />
        </CenterBox>
      </PaddedElevatedCard>
    );
  }

  if (isError || !companies) {
    const message = isError
      ? "Error getting similar companies."
      : "No similar companies";
    return (
      <PaddedElevatedCard>
        <CardTitle>Similar Companies</CardTitle>
        <CenterBox>{message}</CenterBox>
      </PaddedElevatedCard>
    );
  }

  return (
    <PaddedElevatedCard>
      <CardTitle>Similar Companies</CardTitle>
      {companies
        .slice(0, MAX_SIZE_LIST)
        .map(({ company, similarity_index }) => {
          return (
            <Box key={company.id} boxMargin={{ vertical: "sm" }}>
              <FlexBox direction="column" style={{ gap: "0.5em" }}>
                <RelativeLink
                  to={`/company/${company.id}`}
                  onClick={() =>
                    logCustomEvent("clicked_company_in_similarity")
                  }
                >
                  {company.name}
                </RelativeLink>
                <SimilarityIndicator
                  similarity={similarity_index}
                  styling={{ height: 10, maxWidthPct: 90 }}
                />
              </FlexBox>
            </Box>
          );
        })}
    </PaddedElevatedCard>
  );
};
