import { FlexBox } from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";
import React from "react";
import styled from "styled-components";

const ProductDivider = () => {
  return (
    <div
      style={{
        width: "22px",
        height: "2.5px",
        margin: "8px 0px",
        backgroundColor: "#91186E",
      }}
    />
  );
};

type ProductStatProps = {
  title: string;
  value?: number;
};

export const ProductStat = ({ title, value }: ProductStatProps) => {
  return (
    <FlexBox direction="column">
      <StyledValue>{formatProductValue(value)}</StyledValue>
      <ProductDivider />
      <StyledLabel>{title}</StyledLabel>
    </FlexBox>
  );
};

export const formatProductValue = (value?: number): string => {
  if (value) {
    return Intl.NumberFormat("en-GB", {
      notation: "compact",
    }).format(value);
  }
  return "0";
};

const StyledLabel = styled.span`
  font-weight: light;
  color: ${getColor("gray", 900)};
`;

const StyledValue = styled.span`
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.05rem;
  color: ${getColor("gray", 900)};
`;
