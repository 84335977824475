import React, { useMemo, useState } from "react";
import {
  FlexBox,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
} from "@braze/beacon-core";

import { Button } from "primereact/button";
import { DataTable, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { ExternalLink } from "src/components/shared/ExternalLink";

import useDeepCompareEffect from "use-deep-compare-effect";
import { ApptopiaSdkObject } from "src/api/types";
import { formatNumericValue } from "src/util/formatNumericValue";
import styled from "styled-components";

type ApptopiaTableDataProps = {
  data: DataTableValueArray;
  handleSetParams: (arg0: any) => void;
  isLoading: boolean;
  totalDataRecords?: number;
  sdkNames: Array<string>;
};

const initialFilterValue = {
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  total_number_of_apps: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total_mau: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total_downloads: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total_iap_revenue: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total_revenue_per_user: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total_revenue: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  sdks: { value: null, matchMode: FilterMatchMode.EQUALS },
  "apptopia_sdk.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  "apptopia_sdk.install_date": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
};

export const ApptopiaDataTable = ({
  data,
  sdkNames,
  handleSetParams,
  isLoading,
  totalDataRecords,
}: ApptopiaTableDataProps) => {
  console.log(data);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 0,
    filters: initialFilterValue,
  });

  const nameBodyTemplate = (rowData: { name: string; url: string }) => {
    const { name, url } = rowData;
    return (
      <ExternalLink
        href={`${url}`} // Need to check if it includes http.
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </ExternalLink>
    );
  };

  const sdkBodyTemplate = (rowData: { sdks: ApptopiaSdkObject[] }) => {
    const { sdks } = rowData;

    // Get a  unique list/optopns of all SDK names for Filter Drop Down
    const uniqueSdkPartners = new Set();
    const filteredPartnerSDKNames = sdks
      .filter((sdk: ApptopiaSdkObject) => {
        if (sdk.type == "partner") {
          if (uniqueSdkPartners.has(sdk.name)) {
            return false;
          }
          uniqueSdkPartners.add(sdk.name);
          return true;
        }
      })
      .map((sdk: { name: string }) => {
        return sdk.name;
      });

    const uniqueSdkCompetitors = new Set();
    const filteredCompetitorSdkNames = sdks
      .filter((sdk: ApptopiaSdkObject) => {
        if (sdk.type == "competitor") {
          if (uniqueSdkCompetitors.has(sdk.name)) {
            return false;
          }
          uniqueSdkCompetitors.add(sdk.name);
          return true;
        }
      })
      .map((sdk: { name: string }) => {
        return sdk.name;
      });

    return (
      <Table verticalRule width={420} fixed>
        <TableHeader>
          <TableHeaderCell size="compact">Competitors</TableHeaderCell>
          <TableHeaderCell size="compact">Partners</TableHeaderCell>
        </TableHeader>
        <TableBody>
          <TableCell size="compact">
            {filteredCompetitorSdkNames.length > 0 ? (
              filteredCompetitorSdkNames.sort().map((sdk) => {
                return <StyledSdkItem key={sdk}>{sdk}</StyledSdkItem>;
              })
            ) : (
              <ul style={{ margin: 0 }}>-</ul>
            )}
          </TableCell>
          <TableCell size="compact" style={{ marginLeft: "10px" }}>
            {filteredPartnerSDKNames.length > 0 ? (
              filteredPartnerSDKNames.sort().map((sdk) => {
                return <StyledSdkItem key={sdk}>{sdk}</StyledSdkItem>;
              })
            ) : (
              <ul style={{ margin: 0 }}>-</ul>
            )}
          </TableCell>
        </TableBody>
      </Table>
    );
  };

  // This type is hard to pull out as its ReactNode | Function https://primereact.org/datatable/#api.Column.props.filterElement
  const sdkFilterTemplate = (options: {
    value: {
      sdks: {
        sdk_name: string;
        start_date: string | Date | Date[] | null | undefined;
        end_date: string | Date | Date[] | null | undefined;
      };
      end_date: string | Date | Date[] | null | undefined;
    };
    filterCallback: (arg0: any) => void;
  }) => {
    console.log("options type of", typeof options);
    const sdk_names = options.value?.sdks?.sdk_name;
    const start_date = options.value?.sdks?.start_date;
    const end_date = options.value?.sdks?.end_date;

    const sdks = {
      sdk_name: sdk_names,
      start_date: start_date,
      end_date: end_date,
    };

    return (
      <>
        <p>SDK</p>
        <MultiSelect
          filter
          display="chip"
          value={sdk_names || null}
          options={sdkNames}
          onChange={(e) => {
            sdks["sdk_name"] = e.value;
            options.filterCallback({ ...options.value, sdks });
          }}
          placeholder="Any"
          className="p-column-filter"
        />

        <p>Install Date Between</p>
        <p>Date Start</p>
        <Calendar
          value={start_date}
          onChange={(e) => {
            sdks.start_date = e.value;
            options.filterCallback({ ...options.value, sdks });
          }}
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
          mask="99/99/9999"
        />
        <p>Date End</p>
        <Calendar
          value={options.value?.end_date}
          onChange={(e) => {
            sdks.end_date = e.value;
            options.filterCallback({ ...options.value, sdks });
          }}
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
          mask="99/99/9999"
        />
      </>
    );
  };

  const clearFilter = () => {
    setLazyParams({
      first: 0,
      rows: 20,
      page: 0,
      filters: initialFilterValue,
    });
  };
  const formatCell = (value: number, prependChar?: string) => {
    return prependChar
      ? prependChar + formatNumericValue(value)
      : formatNumericValue(value);
  };

  useDeepCompareEffect(() => {
    handleSetParams(lazyParams);
  }, [lazyParams]);

  const Header = useMemo(
    () => (
      <FlexBox style={{ justifyContent: "space-between" }}>
        <Heading>Apps with SDKs</Heading>
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          onClick={clearFilter}
        />
      </FlexBox>
    ),
    []
  );

  const onFilter = (event: any) => {
    setLazyParams(event);
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  return (
    <DataTable
      value={data || []}
      scrollable
      paginator
      loading={isLoading}
      first={lazyParams.first} // need to define original values.
      totalRecords={totalDataRecords}
      showGridlines
      rows={Math.min(totalDataRecords || 0, 20)}
      filters={lazyParams.filters}
      filterDisplay="menu"
      emptyMessage="No Data found."
      header={Header}
      lazy // because this is set, filters and pagination are now controlled. So data is only loaded for page 1. (others dont exist and need to be loaded as well)
      onFilter={onFilter}
      onPage={onPage}
    >
      <Column
        field="name"
        filterField="name"
        header="Name"
        filter
        body={nameBodyTemplate}
      />
      <Column
        field="sdks"
        showFilterMatchModes={false}
        filterField="sdks"
        header="SDKs"
        filter
        body={sdkBodyTemplate}
        filterElement={sdkFilterTemplate}
      ></Column>
      <Column
        field="total_number_of_apps"
        filterField="total_number_of_apps"
        header="Total # of Apps"
        filter
        dataType="numeric"
        body={({ total_number_of_apps }) => formatCell(total_number_of_apps)}
      />
      <Column
        field="total_mau"
        filterField="total_mau"
        header="Total MAU"
        filter
        dataType="numeric"
        body={({ total_mau }) => formatCell(total_mau)}
      ></Column>
      <Column
        field="total_downloads"
        filterField="total_downloads"
        header="Total Downloads"
        filter
        dataType="numeric"
        body={({ total_downloads }) => formatCell(total_downloads)}
      ></Column>
      <Column
        field="total_revenue"
        filterField="total_revenue"
        header="Total Revenue"
        filter
        dataType="numeric"
        body={({ total_revenue }) => formatCell(total_revenue, "$")}
      ></Column>
    </DataTable>
  );
};

const StyledSdkItem = styled.ul`
  padding: 0;
  margin: 5px 0 0 0;
`;
