import React from 'react';
import { FormatLabelProps } from "@braze/beacon-core/dist/components/FieldLabel/types";
import styled from 'styled-components';

const StyledLabel = styled.span`
  color: gray;
`


export const RequiredLabel = ({ label }: FormatLabelProps) => (
  <>
    {label}
    <StyledLabel> (Required)</StyledLabel>
  </>
);