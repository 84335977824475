import { getColor } from "@braze/beacon-styling";
import { css } from "styled-components";

export const baseLinkStyle = css`
color: ${getColor("lightBlue", 500)};
&:hover,
&:active,
&:focus {
  color: ${getColor("lightBlue", 600)};
}
`;
