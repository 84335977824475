import { FlexBox, Heading } from '@braze/beacon-core';
import React, { useCallback, useEffect, useState } from 'react';
import { FiltersPanel } from 'src/components/criteria/FiltersPanel';
import { SubHeading } from 'src/components/shared/SubHeading';
import { ElevatedCard } from 'src/components/shared/ElevatedCard';
import { CompanyDataTable } from 'src/components/similarity/SimilarityResults/CompanyDataTable';
import { Filters } from 'src/components/criteria/FiltersPanel/types';
import { useCriteriaCompass } from 'src/hooks/useCriteriaCompass';
import { CompanyLimited } from 'src/api/types';
import { SearchModel } from 'src/hooks/useCriteriaCompass/useCriteriaCompass';
import { ErrorBanner } from 'src/components/similarity/Banner';

const filtersToSearchModel = (filters?: Filters): SearchModel => {
  if (filters === undefined) {
    return {};
  }
  return {
    industry: filters.industry ? [filters.industry] : [],
    region: filters.region ? [filters.region] : [],
    classification: filters.classification ? [filters.classification] : [],
    partners: filters.partners || [],
    consent: filters.consent || [],
    channels: filters.channels || [],
    hasHighspots: filters?.hasHighspots ? (filters.hasHighspots === "true" ? true : false) : null,
  };
};

const PAGE_SIZE = 10;

export const CompassCriteria = () => {
  const [filters, setFilters] = useState<Filters>();
  const [fetchedCompanyData, setFetchedCompanyData] = useState<CompanyLimited[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(PAGE_SIZE);

  const mutation = useCriteriaCompass();

  const handleFiltersChange = useCallback((formState: Filters) => {
    setFilters(formState);
    setCurrentPage(1);
    if (formState && formState.industry && formState.region) {
      const updatedSearchModel = {
        ...filtersToSearchModel(formState),
        limit: limit,
        offset: 0,
      };
      mutation.mutate(updatedSearchModel);
    }
  }, [limit, mutation]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    const updatedSearchModel = {
      ...filtersToSearchModel(filters),
      limit: limit,
      offset: (page - 1) * limit,
    };
    mutation.mutate(updatedSearchModel);
  }, [filters, limit, mutation]);

  const handleRowsPerPageChange = useCallback((newLimit: number) => {
    setLimit(newLimit);
    setCurrentPage(1);
    const updatedSearchModel = {
      ...filtersToSearchModel(filters),
      limit: newLimit,
      offset: 0,
    };
    mutation.mutate(updatedSearchModel);
  }, [filters, mutation]);

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      setFetchedCompanyData(mutation.data.data);
      setTotalCount(mutation.data.totalCount);
    } else if (mutation.error) {
      console.error('Error fetching data', mutation.error);
    }
  }, [mutation.isSuccess, mutation.data, mutation.error]);


  // map company data to the format expected by the table
  const companyRowData = fetchedCompanyData.map((company) => { return { company: { ...company } } });

  return <>
    <FlexBox
      boxPadding={{ horizontal: "xxxl", vertical: "xxl" }}
      style={{ flex: 1 }}
      direction="column"
    >
      <ElevatedCard style={{ padding: '20px' }}>
        <Heading level={1}>
          Compass Criteria
        </Heading>
        <SubHeading>
          Select the criteria to filter the companies
        </SubHeading>
        <FiltersPanel
          onFormStateChange={handleFiltersChange}
        />

        {mutation.error && <ErrorBanner errorMessage="No data available. Please try again" />}
        {fetchedCompanyData &&
          (<CompanyDataTable
            data={companyRowData}
            isLoading={mutation.isLoading}
            searchType='criteria'
            page={currentPage}
            totalCount={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />)

        }
      </ElevatedCard>
    </FlexBox>
  </>;
};


