import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_API_KEY;

export const DoppelgangerApi = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    common: {
      "Api-Key": API_KEY,
    },
  },
});

export const setAuthorizationHeader = (token: string | undefined) => {
  if (token === undefined) {
    return;
  }

  const bear = `Bearer ${token}`;
  DoppelgangerApi.defaults.headers.common["Authorization"] = bear;
};

export const removeAuthorizationHeader = () => {
  delete DoppelgangerApi.defaults.headers.common["Authorization"];
};
