import { useQuery } from "react-query";
import { get } from "src/api";

export type FilterValues = {
  channels: string[];
  industry: string[];
  region: string[];
  classification: string[];
  consent: string[];
  partners: string[];
  hasHighspots: string[];
};

export const useGetCriteriaFilters = () => {
  return useQuery<FilterValues>("filterValues", () => get<FilterValues>(`companies/filter_values`,), { staleTime: Infinity });
  // Stale time is set to infinity to prevent the query from being refetched when the component is re-rendered
};