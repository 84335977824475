import React from "react";
import { logCustomEvent } from "@braze/web-sdk";
import { RelativeLink } from "src/components/shared/RelativeLink";
import { FlexBox, Icon, Popover, PopoverBody, usePopover } from "@braze/beacon-core";

type TableCompanyLinkProps = {
  id: string;
  name: string;
  description: string | null;
  setModalCompany?: (companyId: string) => void;
  withModal?: boolean;
};

export const TableCompanyLink = ({
  id,
  name,
  description,
  setModalCompany,
  withModal
}: TableCompanyLinkProps) => {
  const [descPopoverState, targetRef] = usePopover<HTMLAnchorElement>();
  const [modalPopoverState, modalPopoverRef] = usePopover<HTMLAnchorElement>();

  let descText = description || "No description";
  if (descText.length > 250) {
    descText = descText.substring(0, 250) + "...";
  }

  return (
    <FlexBox style={{ gap: 3 }}>
      <RelativeLink
        ref={targetRef}
        to={`/company/${id}`}
        onClick={() => logCustomEvent("clicked_company_in_table")}
      >
        {name}
      </RelativeLink>
      <Popover
        {...descPopoverState}
        targetRef={targetRef}
        triggers={["mouseenter"]}
        untriggers={["mouseleave"]}
      >
        <PopoverBody>{descText}</PopoverBody>
      </Popover>
      {withModal && setModalCompany && (
        <>
          <Icon ref={modalPopoverRef} name="magnifying-glass" size="sm" style={{ color: '#300266' }} onClick={() => setModalCompany(id)} />
          <Popover
            {...modalPopoverState}
            targetRef={modalPopoverRef}
            triggers={["mouseenter"]}
            untriggers={["mouseleave"]}>
            <PopoverBody>Open Company Modal</PopoverBody>
          </Popover>

        </>
      )}
    </FlexBox>

  );
};
