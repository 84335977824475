import { useQuery } from "react-query";
import { get } from "src/api";
import { ApptopiaPublisherObject } from "src/api/types";

type ApptopiaData = {
  data: ApptopiaPublisherObject;
  total_results: number;
};

export const useApptopiaPublisher = (params: any) => {
  const queryParams = JSON.stringify(params);

  return useQuery(["apps", params], () =>
    get<ApptopiaData>(`companies/apps`, {
      params: queryParams,
    })
  );
};