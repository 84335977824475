import React from "react";
import styled from "styled-components";
import { FlexBox, Icon, Modal, ModalBody, ModalHeading, Popover, PopoverBody, usePopover } from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";

import { ExternalLink } from "src/components/shared/ExternalLink";
import { useCompany } from "src/hooks/useCompany";
import { Loading } from "src/components/shared/Loading";
import { logCustomEvent } from "@braze/web-sdk";
import { WebsiteText } from "src/components/dashboard/CompanyInformation/styles";
import { HighSpots } from "src/components/dashboard/HighSpots";
import { DopperHeading } from "src/components/shared/DopperHeading";
import { FlatList } from "src/components/shared/FlatList";
import { useNavigate } from "react-router-dom";

export type CompanyModalProps = {
  id: string;
  show: boolean;
  toggle: () => void;
};

export const CompanyModal = ({ id, show, toggle }: CompanyModalProps) => {
  const navigate = useNavigate();
  const [modalPopoverState, modalPopoverRef] = usePopover<HTMLAnchorElement>();


  const { data, isLoading, isError } = useCompany(id || "");

  if ((!data && !isLoading) || isError) {
    return (
      <Modal size={"md"} isOpen={show} toggle={toggle}>
        <ModalHeading level={3}></ModalHeading>
        <ModalBody>Cannot load Company data</ModalBody>
      </Modal>
    )
  }

  if (isLoading || !data) {
    return (
      <Modal size={"md"} isOpen={show} toggle={toggle}>
        <ModalHeading></ModalHeading>
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    )
  }

  const contact = [
    {
      label: "Account Owner",
      value: data.account_owner ? data.account_owner.name : "NA",
      url: data.account_owner
        ? `https://braze.lightning.force.com/${data.account_owner.id}`
        : undefined,
    },
    {
      label: "Onboarding Manager",
      value: data.onboarding_manager
        ? data.onboarding_manager.name
        : "NA",
      url: data.onboarding_manager
        ? `https://braze.lightning.force.com/${data.onboarding_manager.id}`
        : undefined,
    },
    {
      label: "Success Manager",
      value: data.success_manager ? data.success_manager.name : "NA",
      url: data.success_manager
        ? `https://braze.lightning.force.com/${data.success_manager.id}`
        : undefined,
    },
  ];

  return (
    <Modal size={"md"} isOpen={show} toggle={toggle}>
      <ModalHeading>
        <FlexBox style={{ gap: 5 }}>
          <DopperHeading style={{ marginTop: 5 }}>
            {data.name}
          </DopperHeading>
          <Icon ref={modalPopoverRef} name="arrow-up-right-from-square" size="sm" style={{ color: '#300266' }} onClick={() => {
            navigate(`/company/${id}`);
          }} />
          <Popover
            {...modalPopoverState}
            targetRef={modalPopoverRef}
            triggers={["mouseenter"]}
            untriggers={["mouseleave"]}>
            <PopoverBody>Open Company Dashboard</PopoverBody>
          </Popover>
        </FlexBox>

      </ModalHeading>
      <ModalBody>
        <FlexBox direction="column" style={{ gap: 15 }}>

          <FlexBox direction="column">
            <Description>{data.description}</Description>

            <WebsiteText>
              Website:{" "}
              <ExternalLink href={data.website || ""} target="_blank">
                {data.website}
              </ExternalLink>
            </WebsiteText>
            <WebsiteText>
              Salesforce:{" "}
              <ExternalLink
                href={`https://braze.lightning.force.com/${data.id}`}
                target="_blank"
                onClick={() => logCustomEvent("clicked_salesforce_link")}
              >
                {data.id}
              </ExternalLink>
            </WebsiteText>
          </FlexBox>

          <FlatList title="Contact" data={contact} />
          <HighSpots caseStudies={data.highspots} companyId={data.id} companyOnly />
        </FlexBox>
      </ModalBody>
    </Modal >
  );

};

const Description = styled.p`
  color: ${getColor("gray", 600)};
  margin-top: 0;
  line-height: 1.33rem;
`;


export default CompanyModal;
