import { logCustomEvent } from "@braze/web-sdk";
import { useMutation } from "react-query";
import { post } from "src/api";
import { CompanyLimited } from "src/api/types";

// Define the response structure as a type
export type CriteriaSearchResponse = {
  data: CompanyLimited[];
  totalCount: number;
  currentPage: number;
  totalPages?: number;
};

export type SearchModel = {
  industry?: string[];
  region?: string[];
  classification?: string[] | null;
  partners?: string[] | null;
  consent?: string[] | null;
  channels?: string[] | null;
  hasHighspots?: boolean | null;
  limit?: number | null;
  offset?: number | null;
};

// TODO: will need to re-review caching and efficiency.
// The hook is called for every result change (including backwards), which is not ideal.
export const useCriteriaCompass = () => {

  return useMutation<CriteriaSearchResponse, unknown, SearchModel>(
    (body: SearchModel) => {
      logCustomEvent("compass_criteria_used", body);
      return post<CriteriaSearchResponse>(`companies/search/criteria`, body);
    },
  );
};