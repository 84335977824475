import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Loading } from "src/components/shared/Loading";

export const RequiresAuth = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return <Loading />;
  }

  if (!authState?.isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  }

  return <Outlet />;
};
