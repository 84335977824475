import React from "react";
import { FlexBox } from "@braze/beacon-core";
import { HighspotObject } from "src/api/types";
import { DopperHeading } from "src/components/shared/DopperHeading";
import { CaseStudies } from "./CaseStudies";
import { useSimilarHighspot } from "src/hooks/useSimilarHighspot";

type HighSpotsProps = {
  caseStudies: HighspotObject[];
  companyId: string;
  companyOnly?: boolean
};

export const HighSpots = ({ caseStudies, companyId, companyOnly }: HighSpotsProps) => {
  const {
    data: industryCaseStudies,
    isLoading: loading,
    isError,
  } = useSimilarHighspot(companyId);

  return (
    <FlexBox direction="column">
      <DopperHeading
        size="xs"
        fontWeight={600}
        style={{ marginTop: 12, marginBottom: 12, color: "#801ED7" }}
      >
        Case Studies
      </DopperHeading>
      <CaseStudies {...{ caseStudies, title: !companyOnly ? "Company" : undefined }} />
      {!companyOnly &&
        < CaseStudies
          {...{
            caseStudies: industryCaseStudies || [],
            loading,
            isError,
            title: "Industry",
          }}
        />}
    </FlexBox>
  );
};
