import React from "react";
import { BodyText, Box, Button, FlexBox, Heading } from '@braze/beacon-core';
import backgroundImage from './../assets/welcome-background.png';
import slidersImage from "./../assets/sliders-04.png";
import textInputImage from "./../assets/text-input.png"
import styled from "styled-components";
import { Link } from "react-router-dom";
import { logCustomEvent } from "@braze/web-sdk";

export const App = () => {
  return (
    <>
      <FlexBox
        style={{ flex: 1 }}
        direction="column"
      >
        <FlexBox
          style={{ flex: 1, backgroundImage: `url(${backgroundImage})` }}
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <StyledFlexBox
            justifyContent="center"
            direction="column"
            alignItems="center" >

            <Heading style={{ color: "#300266" }}>
              Welcome to Doppelgänger
            </Heading>
            <BodyText style={{ width: "75%" }}>
              Optimize your strategies with Braze&apos;s Doppelgänger tool. Search by name for similar companies or define criteria for a tailored customer list.
            </BodyText>
          </StyledFlexBox>
        </FlexBox>

        <FlexBox style={{ flex: 1 }}>
          <FlexBox
            style={{ flex: 1 }}>
            <FlexBox
              style={{ flex: 1, backgroundColor: "#300266" }}
            >
              <StyledLink to="/similarity" onClick={() => logCustomEvent("clicked_similarity_search")}>
                <FlexBox
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  style={{ flex: 1, gap: 20, padding: 30 }}
                >
                  <img src={textInputImage} alt="Text Input Image" width="50" height="50" />
                  <Heading level={3} style={{ color: "white" }}>
                    Search by Name
                  </Heading>
                  <BodyText
                    style={{ color: "white", width: "60%" }}
                  >
                    Utilize our traditional search functionality to find companies similar to our existing Braze customers.
                  </BodyText>
                  <StyledButton>
                    Search
                  </StyledButton>

                </FlexBox>
              </StyledLink>
            </FlexBox>


          </FlexBox>
          <FlexBox
            style={{ flex: 1 }}>
            <FlexBox
              justifyContent="center"
              style={{ flex: 1, backgroundColor: "#801ED7" }}
            >
              <StyledLink to="/criteria" onClick={() => logCustomEvent("clicked_criteria_search")}>
                <FlexBox
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  style={{ flex: 1, gap: 20, padding: 30 }}
                >
                  <img src={slidersImage} alt="Text Input Image" width="50" height="50" />
                  <FlexBox style={{ gap: 5 }}>
                    <Heading level={3} style={{ color: "white", marginTop: '2px' }}>
                      Search by Criteria
                    </Heading>
                    <Pill>New!</Pill>
                  </FlexBox>
                  <BodyText
                    style={{ color: "white", width: "60%" }}
                  >
                    Filter your search by specific criteria i.e. industry, region, channels used.
                  </BodyText>
                  <StyledButton>
                    Search
                  </StyledButton>

                </FlexBox>
              </StyledLink>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox >
    </>
  );
};


export const Pill = styled(Box)`
  justify-content: center;
  align-content: center;
  padding: 2.5px 16px;
  border-radius: 20px;
  max-width: fit-content;
  letter-spacing: 0.05rem;
  font-weight: 600;
  color: black;
  background: #FFA524;
  border-color: #801ED7;
  border: inset;
  fontSize: 12;
  margin-top: 0;
`;


const StyledButton = styled(Button)`
  color: #300266;
  background-color: #FFA4FB;
  border-radius: 10px;
  border-color: #FFA4FB;
  &&:hover {
    background-color: #300266;
    color: white;
    transition: all 0.2s ease-in-out;
    border-color: #300266;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #FFA524;
  }
`;

const StyledFlexBox = styled(FlexBox)`
  position: relative;
  padding: 30px;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    z-index: -1;
    border-radius: 10px;
  }
`;

