import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { BarLoader } from "react-spinners";
import { SimilarityIndicator } from "../../SimilarityIndicator";
import { CompanyRow } from "../../types";
import { TableCompanyLink } from "./TableCompanyLink";
import { CompanyModal } from "../../CompanyModal";
import { formatNumericValue } from "src/util/formatNumericValue";
import { FlatList } from "src/components/shared/FlatList";

const TABLE_RADIUS = 10;

type CompanyDataTableProps = {
  data: CompanyRow[];
  isLoading: boolean;
  page?: number;
  onChangePage?: (page: number) => void; // Handler for page change
  searchType?: SearchType;
  totalCount?: number;
  pageSize?: number;
  onChangeRowsPerPage?: (limit: number) => void; // Handler for rows per page change
};

type SearchType = 'default' | 'criteria' | 'similarity';

export const CompanyDataTable = ({
  data,
  isLoading,
  page,
  onChangePage,
  totalCount,
  pageSize,
  onChangeRowsPerPage,
  searchType = "default"
}: CompanyDataTableProps) => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

  const onCompanyNameClick = (companyId: string) => {
    setSelectedCompany(companyId);
    setShowCompanyModal(true);
  };

  const columns: TableColumn<CompanyRow>[] = [
    ...(searchType === "similarity" ? [{
      name: "Similarity",
      selector: (row: { similarity_index?: number; }) => row.similarity_index || 0,
      cell: (row: { similarity_index?: number; }) => <SimilarityIndicator similarity={row.similarity_index || 0} />,
    }] : []),
    {
      name: "Name",
      selector: (row) => row.company.name,
      cell: (row) => (
        <TableCompanyLink id={row.company.id} name={row.company.name} description={row.company.description} withModal setModalCompany={onCompanyNameClick} />

      ),
    },
    {
      name: "Region",
      selector: (row) => row.company.region,
    },
    {
      name: "Classification",
      selector: (row) => row.company.classification,
    },
    {
      name: "Industry",
      selector: (row) => row.company.industry_micro,
    },
    {
      name: "Consent",
      cell: (row: CompanyRow) => {
        const consent = [
          {
            label: "Verbal",
            value: row.company.verbal_consent,
          },
          {
            label: "Written",
            value: row.company.written_consent,
          },
          {
            label: "Logo",
            value: row.company.logo_consent,
          },
        ];
        return <FlatList data={consent} />
      },
    },
    ...(searchType === "criteria" ? [
      {
        name: "Channels",
        cell: (row: CompanyRow) => {
          const channels = {
            "IAM Impressions": row.company.iam_impressions,
            "Mobile Pushes": row.company.mobile_pushes,
            "Web Pushes": row.company.web_pushes,
            "Emails": row.company.emails,
            "Content Cards": row.company.content_cards,
            "SMS": row.company.sms,
            "Roku": row.company.roku,
          };
          return (
            <div>
              {Object.entries(channels).map(([channel, count]) => (
                count > 0 && <div key={channel}>
                  {channel}: <strong>{formatNumericValue(count)}</strong>
                </div>
              ))}
            </div>
          );
        },
      },
    ] : []),
  ];

  return (
    <>
      <DataTable
        striped
        highlightOnHover
        columns={columns}
        data={data}
        progressPending={isLoading}
        progressComponent={<TableLoader />}
        pagination
        paginationServer={searchType === "criteria"}
        paginationTotalRows={totalCount}
        paginationDefaultPage={page || 1}
        onChangePage={onChangePage}
        paginationPerPage={pageSize}
        onChangeRowsPerPage={onChangeRowsPerPage}
        customStyles={{
          header: {
            style: {
              borderRadius: TABLE_RADIUS,
            },
          },
          pagination: {
            style: {
              borderBottomLeftRadius: TABLE_RADIUS,
              borderBottomRightRadius: TABLE_RADIUS,
            },
          },
          head: {
            style: {
              color: "#801ED7",
              textTransform: "uppercase",
              fontWeight: 600,
              letterSpacing: "0.03rem",
            },
          },
          responsiveWrapper: {
            style: {
              borderRadius: TABLE_RADIUS,
            },
          },
        }}
      />
      {showCompanyModal && selectedCompany && (
        <CompanyModal
          id={selectedCompany}
          show={showCompanyModal}
          toggle={() => setShowCompanyModal(!showCompanyModal)}
        />
      )}
    </>
  );
};

const TableLoader = () => (
  <BarLoader
    color="#801ED7"
    width="33%"
    cssOverride={{ marginTop: 12, marginBottom: 36 }}
  />
);
