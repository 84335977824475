import { FlexBox } from "@braze/beacon-core";
import React from "react";
import { PartnerObject } from "../../../api/types";
import styled from "styled-components";
import { PaddedElevatedCard } from "../PaddedElevatedBox";
import { CardTitle } from "../CardTitle";

type PartnerProps = {
  partners: PartnerObject[];
};

export const PartnerInformation = ({ partners }: PartnerProps) => {
  return (
    <PaddedElevatedCard>
      <CardTitle>Partners</CardTitle>
      {!partners.length ? (
        <span>No partners used</span>
      ) : (
        <>
          {partners.map((partner) => {
            return (
              <PartnerRow key={partner.partner + "_" + partner.partner_class}>
                <PartnerName>{partner.partner}</PartnerName>
                <PartnerClass>{partner.partner_class}</PartnerClass>
              </PartnerRow>
            );
          })}
        </>
      )}
    </PaddedElevatedCard>
  );
};

const PartnerRow = styled(FlexBox)`
  align-items: baseline;
  gap: 10px;
`;

const PartnerName = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const PartnerClass = styled.span`
  font-size: 14px;
  font-weight: 300;
`;
