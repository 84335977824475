import React from "react";
import { CenterBox } from "src/components/shared/CenterBox";
import SherlockTorchie from "src/assets/torchie-sherlock.png";
import { DopperHeading } from "src/components/shared/DopperHeading/DopperHeading";

export const WelcomeBanner = () => {
  return (
    <CenterBox style={{ flex: 1, flexDirection: "column" }}>
      <img src={SherlockTorchie} alt="torchie-explorer" />
      <DopperHeading size="lg" fontWeight={300} style={{ marginTop: "20px" }}>
        Enter a company name to search for similar Braze customers
      </DopperHeading>
    </CenterBox>
  );
};
