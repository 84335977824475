import React from "react";
import { LoginCallback } from "@okta/okta-react";
import { Route, Routes } from "react-router-dom";
import { Login } from "src/pages/Login";
import { NotFound } from "src/pages/NotFound";
import { RequiresAuth } from "./SecureRoute";
import { CompanyDashboard } from "src/pages/CompanyDashboard";
import { Similarity } from "src/pages/Similarity";
import { Apptopia } from "src/pages/Apptopia";
import { CompassCriteria } from "src/pages/CompassCriteria";
import { App } from "src/pages";

export const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/callback" element={<LoginCallback />} />˝
        <Route element={<RequiresAuth />}>
          <Route path="/" element={<App />} />
          <Route path="/similarity" element={<Similarity />} />
          <Route path="/company/:id" element={<CompanyDashboard />} />
          <Route path="/apps" element={<Apptopia />} />
          <Route path="/criteria" element={<CompassCriteria />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
