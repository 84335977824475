import { Link } from "react-router-dom";
import { baseLinkStyle } from "src/shared/styles/link";
import styled from "styled-components";

/**
 * Link used for relative, internal navigation within the react router
 */
export const RelativeLink = styled(Link)`
  ${baseLinkStyle}
`;
