import styled, { css } from "styled-components";

const baseTableTitleStyle = css`
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  font-weight: 400;
`;

export const SubHeading = styled.span`
  ${baseTableTitleStyle}
`;