import { Heading } from "@braze/beacon-core";
import React from "react";
import { CenterBox } from "src/components/shared/CenterBox";
import ExpressionlessTorchie from "src/assets/torchie-expressionless.png";

export const ErrorBanner = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <CenterBox style={{ flex: 1, flexDirection: "column" }}>
      <img
        src={ExpressionlessTorchie}
        alt="sorry-torchie"
        style={{ opacity: "80%" }}
      />
      <Heading style={{ fontWeight: 400, marginTop: 12 }}>Oops!</Heading>
      <Heading
        level={3}
        style={{
          fontWeight: 400,
          marginTop: 8,
          whiteSpace: "pre",
          textAlign: "center",
        }}
      >
        {errorMessage}
      </Heading>
    </CenterBox>
  );
};
