import React from "react";
import { baseLinkStyle } from "src/shared/styles/link";
import styled from "styled-components";

/**
 * Link to be used for links outside of the application router (external link).
 */
export const ExternalLink = styled.a`
  ${baseLinkStyle}
`;
